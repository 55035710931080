import {
  FACEBOOK_HASHTAGS_LIMIT,
  INSTAGRAM_HASHTAGS_LIMIT,
  CHANNEL_FACEBOOK,
  CHANNEL_INSTAGRAM,
} from '@/destinations/constants/Constants';

/**
 * @param {{channel_type: number}} destination
 * @returns {number|null}
 */
function getDestinationHashtagLimit(destination) {
  switch (destination.channel_type) {
    case CHANNEL_FACEBOOK:
      return FACEBOOK_HASHTAGS_LIMIT;
    case CHANNEL_INSTAGRAM:
      return INSTAGRAM_HASHTAGS_LIMIT;
    default:
      return null;
  }
}

export default getDestinationHashtagLimit;
