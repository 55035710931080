import getTwitterMessageLimit from '@/destinations/utils/getTwitterMessageLimit';
import getLinkedInMessageLimit from '@/destinations/utils/getLinkedInMessageLimit';
import {
  CHANNEL_TWITTER,
  CHANNEL_PINTEREST,
  CHANNEL_LINKEDIN,
  CHANNEL_LINKEDIN_PROFILE,
  CHANNEL_FACEBOOK,
  CHANNEL_INSTAGRAM,
  CHANNEL_GOOGLE_BUSINESS,
  CHANNEL_THREADS,
} from '@/destinations/constants/Constants';

function getDestinationMessageLimit(destination, post) {
  let messageLimit;
  switch (destination.channel_type) {
    case CHANNEL_TWITTER:
      messageLimit = getTwitterMessageLimit(post);
      break;
    case CHANNEL_PINTEREST:
      messageLimit = 500;
      break;
    case CHANNEL_LINKEDIN:
    case CHANNEL_LINKEDIN_PROFILE:
      messageLimit = getLinkedInMessageLimit(post);
      break;
    case CHANNEL_FACEBOOK:
      messageLimit = 63206;
      break;
    case CHANNEL_INSTAGRAM:
      messageLimit = 2200;
      break;
    case CHANNEL_GOOGLE_BUSINESS:
      messageLimit = 1500;
      break;
    case CHANNEL_THREADS:
      messageLimit = 500;
      break;
    default:
      messageLimit = null;
      break;
  }
  return messageLimit;
}

export default getDestinationMessageLimit;
