import { createSelector } from 'reselect';
import getDestinationMessageLimit from '@/destinations/utils/getDestinationMessageLimit';
import tryParseJson from '@/storychief/utils/tryParseJson';
import getDestinationMessageLength from '@/destinations/utils/getDestinationMessageLength';
import getHashtagLength from '@/destinations/utils/getHashtagLength';
import getDestinationHashtagLimit from '@/destinations/utils/getDestinationHashtagLimit';

const getPost = (state) => state.post;
const getPostMessage = (state) => state.post.message;
const getPostDestination = (state) => state.post.destination;
const getPostsetMessage = (state) => state.postset.message;

export const getPostGroupedPosts = createSelector([getPost], (post) => {
  let grouped_posts = [];
  let requiredProperties = ['grouped', 'published_at', 'postset', 'scheduled'];
  requiredProperties.forEach((prop) => {
    if (post[prop] === undefined) {
      throw Error(`getPostGroupedPosts is missing property ${prop}`);
    }
  });

  if (post.grouped && !post.published_at && !post.scheduled) {
    requiredProperties = ['posts'];
    requiredProperties.forEach((prop) => {
      if (post.postset[prop] === undefined) {
        throw Error(`getPostGroupedPosts is missing property ${prop}`);
      }
    });
    grouped_posts = post.postset.posts.filter((_postsetPost) => {
      requiredProperties = ['destination', 'id', 'grouped', 'published_at', 'scheduled'];
      requiredProperties.forEach((prop) => {
        if (_postsetPost[prop] === undefined) {
          throw Error(`getPostGroupedPosts is missing property ${prop}`);
        }
      });
      return (
        _postsetPost.destination.channel_type === post.destination.channel_type &&
        _postsetPost.id !== post.id &&
        _postsetPost.grouped &&
        // Published and scheduled posts may not be ungrouped or grouped so we exclude them
        !_postsetPost.published_at &&
        !_postsetPost.scheduled
      );
    });
  }
  return grouped_posts;
});

export const getPostUngroupedPosts = createSelector([getPost], (post) => {
  let ungrouped_posts = [];
  let requiredProperties = ['grouped', 'published_at', 'postset', 'scheduled'];
  requiredProperties.forEach((prop) => {
    if (post[prop] === undefined) {
      throw Error(`getPostUngroupedPosts is missing property ${prop}`);
    }
  });
  if (!post.grouped && !post.published_at && !post.scheduled) {
    requiredProperties = ['posts'];
    requiredProperties.forEach((prop) => {
      if (post.postset[prop] === undefined) {
        throw Error(`getPostUngroupedPosts is missing property ${prop}`);
      }
    });
    ungrouped_posts = post.postset.posts.filter((_postsetPost) => {
      requiredProperties = ['destination', 'id', 'grouped', 'published_at', 'scheduled'];
      requiredProperties.forEach((prop) => {
        if (_postsetPost[prop] === undefined) {
          throw Error(`getPostUngroupedPosts is missing property ${prop}`);
        }
      });
      return (
        _postsetPost.destination.channel_type === post.destination.channel_type &&
        _postsetPost.id !== post.id &&
        !_postsetPost.grouped &&
        // Published & scheduled posts may not be ungrouped or grouped so we exclude them
        !_postsetPost.published_at &&
        !_postsetPost.scheduled
      );
    });
  }
  return ungrouped_posts;
});

export const getPostGroupedPostsWithoutTrashed = createSelector(
  [getPostGroupedPosts],
  (postGroupedPosts) =>
    postGroupedPosts.filter((_post) => {
      const requiredProperties = ['deleted_at'];
      requiredProperties.forEach((prop) => {
        if (_post[prop] === undefined) {
          throw Error(`getPostUngroupedPosts is missing property ${prop}`);
        }
      });
      return !_post.deleted_at;
    }),
);

export const getPostUngroupedPostsWithoutTrashed = createSelector(
  [getPostUngroupedPosts],
  (postUngroupedPosts) =>
    postUngroupedPosts.filter((_post) => {
      const requiredProperties = ['deleted_at'];
      requiredProperties.forEach((prop) => {
        if (_post[prop] === undefined) {
          throw Error(`getPostGroupedPostsWithoutTrashed is missing property ${prop}`);
        }
      });
      return !_post.deleted_at;
    }),
);

export const getPostDestinationShouldShowDetails = createSelector(
  [getPost, getPostGroupedPostsWithoutTrashed],
  (post, postGroupedPosts) => {
    const requiredProperties = ['grouped'];
    requiredProperties.forEach((prop) => {
      if (post[prop] === undefined) {
        throw Error(`getPostDestinationShouldShowDetails is missing property ${prop}`);
      }
    });
    if (post && !post.grouped) return true;
    return !postGroupedPosts.length;
  },
);

export const getPostChannelName = createSelector(
  [getPost, getPostDestinationShouldShowDetails],
  (post, postDestinationShouldShowDetails) => {
    const requiredProperties = ['destination'];
    requiredProperties.forEach((prop) => {
      if (post[prop] === undefined) {
        throw Error(`getPostChannelName is missing property ${prop}`);
      }
    });
    if (postDestinationShouldShowDetails) {
      return post.destination.display_name;
    }
    return post.destination.channel_name;
  },
);

export const getPostMessageLimitReached = createSelector(
  [getPost, getPostDestination, getPostMessage, getPostsetMessage],
  (post, postDestination, postMessage, postsetMessage) => {
    const messageLimit = getDestinationMessageLimit(postDestination, post);
    if (!messageLimit) {
      return false;
    }
    const messageTemp = postMessage === null ? postsetMessage : postMessage;
    const parsedMessageTemp = tryParseJson(messageTemp);
    const message =
      parsedMessageTemp && parsedMessageTemp.parsed && parsedMessageTemp.parsed.blocks[0]
        ? parsedMessageTemp.parsed.blocks[0].text
        : '';
    const messageLength = getDestinationMessageLength(message, postDestination.channel_type);
    const messageCount = messageLimit ? messageLimit - messageLength : messageLength;
    return messageCount < 0;
  },
);

export const getHashtagLimitReached = createSelector(
  [getPostDestination, getPostMessage, getPostsetMessage],
  (postDestination, postMessage, postsetMessage) => {
    const hashtagLimit = getDestinationHashtagLimit(postDestination);
    if (!hashtagLimit) return false;
    const messageTemp = postMessage === null ? postsetMessage : postMessage;
    const parsedMessageTemp = tryParseJson(messageTemp);
    const message =
      parsedMessageTemp && parsedMessageTemp.parsed && parsedMessageTemp.parsed.blocks[0]
        ? parsedMessageTemp.parsed.blocks[0].text
        : '';
    return getHashtagLength(message) > hashtagLimit;
  },
);
