import { convertFromRaw } from 'draft-js';
import getEntities from '@/editor/utils/getEntities';

/**
 * @param {object} post
 * @param {string} post.message
 * @returns {number}
 */
export default function getLinkedInMessageLimit(post) {
  let limit = 3000;

  if (post.message) {
    const draftParsed = JSON.parse(post.message);
    const contentState = convertFromRaw(draftParsed);
    const entities = getEntities(contentState);

    // Before sending the text to the LinkedIn API, we convert the message to LinkedIn little text.
    // The conversion wraps each mention with the MentionElement commentary text, which LinkedIn treats
    // as normal text in the message limit calculation.
    entities.forEach(({ entity }) => {
      limit -= `@[](urn:li:organization:${entity.data.id})`.length;
    });
  }

  return limit;
}
