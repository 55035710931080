const MAX_FILE_SIZE = 512; // MB
const MAX_DIMENSIONS = {
  width: 1280,
  height: 1024,
};
const MAX_LENGTH = 140; // Seconds

export default function getTwitterVideoLimitErrors(video) {
  const errors = [];

  // Value of file_size is in bytes.
  if (video.file_size / 1000000 > MAX_FILE_SIZE) {
    errors.push(`file size larger than ${MAX_FILE_SIZE}MB`);
  }

  if (video.length > MAX_LENGTH) {
    errors.push(`duration longer than ${MAX_LENGTH} seconds`);
  }

  if (video.width > MAX_DIMENSIONS.width || video.height > MAX_DIMENSIONS.height) {
    errors.push(`dimension larger than ${MAX_DIMENSIONS.width}x${MAX_DIMENSIONS.height}`);
  }

  return errors;
}
