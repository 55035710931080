import { AttachmentType } from '@/postsets/constants/Constants';

type Attachment = {
  attachable: {
    __typename: AttachmentType;
  };
};

function getPostsetAttachmentsType(attachments: Attachment[]): AttachmentType | null {
  if (attachments && attachments.length) {
    const attachmentType: AttachmentType = attachments[0].attachable.__typename;

    if (attachments.length > 1) {
      const isIncludesVideos = attachments.some((a) => a.attachable.__typename === 'Video');
      const isIncludesImages = attachments.some((a) => a.attachable.__typename === 'Image');

      if (isIncludesVideos && isIncludesImages) {
        return 'Media';
      }
    }

    return attachmentType;
  }

  return null;
}

export default getPostsetAttachmentsType;
