import twitterText from 'twitter-text';
import { CHANNEL_TWITTER } from '@/destinations/constants/Constants';

/**
 * Return the destination message length.
 * @param {string} message
 * @param {number} channelType
 * @returns {number}
 */
export default function getDestinationMessageLength(message, channelType) {
  if (channelType === CHANNEL_TWITTER) {
    const tweet = twitterText.parseTweet(message);
    return tweet.weightedLength;
  }

  return message.length;
}
