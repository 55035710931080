import getPostsetAttachmentsType from '@/postsets/utils/getPostsetAttachmentsType';
import getTwitterVideoLimitErrors from '@/posts/utils/getTwitterVideoLimitErrors';
import includesUrl from '@/storychief/utils/includesUrl';
import { TWITTER_LIMIT, TWITTER_LINK_LENGTH } from '@/destinations/constants/Constants';

function getMessage({ message, postset } = {}) {
  return message || postset?.message || null;
}

export default function getTwitterMessageLimit(post) {
  let limit = TWITTER_LIMIT;

  if (post) {
    const type = getPostsetAttachmentsType(post.postset.attachments);
    const attachment = post.postset.attachments[0];
    const message = getMessage(post);

    // StoryChief appends a link at the end of the tweet for certain types.
    if (
      (type === 'Link' && !includesUrl(message, attachment?.attachable?.url)) ||
      type === 'Story' ||
      (type === 'Video' && getTwitterVideoLimitErrors(attachment?.attachable).length > 0)
    ) {
      // We need 1 extra character for the space between the message and link.
      limit -= TWITTER_LINK_LENGTH + 1;
    }
  }

  return limit;
}
